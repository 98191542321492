import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Button, LabeledInput, Row, Col, LabeledSelect, IconDoc } from '@commonsku/styles';
import { currencies, invoice_view_options, shipdate_options, presentation_style_options } from '../../../company_setting_options';
import { useIdentity } from '../../../hooks';
import { getIdentityUtils, getImageSrc } from '../../../utils';
import useTenantUsers from '../hooks/useTenantUsers';
import Avatar from 'react-avatar';

export default function CompanyGeneralFormNumbers({ taxes, data, loading, save, saveExtra }) {
    const identity = useIdentity();
    const [users,] = useTenantUsers();
    const { hasUserFlags } = getIdentityUtils(identity);
    const newDesignEnabled = data?.companyData?.default_presentation_style === 'SHOP_DESIGN'
        || hasUserFlags('SHOP_DESIGN_ENABLED');
    const presentation_options = useMemo(
        () => newDesignEnabled
            ? presentation_style_options
            : presentation_style_options.filter(v => v.value !== 'SHOP_DESIGN'),
        [newDesignEnabled]
    );

    const defaultValues = useMemo(() => ({
        default_invoice_view: '',
        default_currency_id: '',
        default_shipdate_option: '',
        default_presentation_style: '',
        default_tax_id: '',
        default_margin: '',
        default_client_rep_id: '',
    }), []);

    const [form, setForm] = useState(defaultValues);
    const [formErrors, setFormErrors] = useState(defaultValues);

    const currency_options = currencies.map(c => ({ label: c, value: c }));
    const tax_options = taxes.map(c => ({ label: c.label, value: c.tax_id }));
    const user_options = users.map(u => ({
        label: <><Avatar src={u.user_image_paths?.small || null} name={u.contact_full_name} round={true} size='30' /> {u.contact_full_name}</>, value: u.user_id
    }));

    useEffect(() => {
        let values = defaultValues;
        if (data.default_currency_id) {
            values = {
                ...values,
                default_invoice_view: data.default_invoice_view,
                default_currency_id: data.default_currency_id,
            };
        }
        if (data.companyData) {
            values = {
                ...values,
                default_presentation_style: data.companyData.default_presentation_style,
                default_shipdate_option: data.companyData.default_shipdate_option,
                default_tax_id: data.companyData.default_tax_id,
                default_margin: data.companyData.default_margin,
                default_client_rep_id: data.companyData.default_client_rep_id,
            };
        }
        setForm(values);
    }, [data.companyData, data.default_currency_id, data.default_invoice_view, data.default_presentation_style, data.default_shipdate_option, defaultValues]);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => ({ ...form, [target.name]: target.value }));
    }, []);

    const validateForm = useCallback((data) => {
        setFormErrors(defaultValues);
        let errorCount = 0;
        // Check data and set form errors
        for (const field of ['default_invoice_view', 'default_currency_id', 'default_shipdate_option', 'default_presentation_style', 'default_tax_id', 'default_margin', 'default_client_rep_id']) {
            if (data[field] === '') {
                setFormErrors((errors) => ({ ...errors, [field]: 'Required' }));
                errorCount++;
            }
        }

        if (errorCount > 0) return false;

        return true;
    }, [defaultValues]);

    const getSelect = ({ label, name, options }) => {
        let value = { label: form[name], value: form[name] };
        if (name === 'default_tax_id' || name === 'default_client_rep_id') {
            value = options.filter(c => c.value === form[name]);
        } else if (['default_presentation_style', 'default_shipdate_option', 'default_invoice_view'].includes(name)) {
            value = options.find(c => c.value === form[name]);
        }
        return (
            <>
                <LabeledSelect
                    label={label}
                    name={name}
                    value={value}
                    options={options}
                    onChange={(item) => {
                        setFormFieldValue({ target: { name: name, value: item.value } });
                        const newData = { ...form, [name]: item.value };
                        if (!validateForm(newData)) {
                            return;
                        }
                        save(newData);
                        saveExtra(newData);
                    }}
                />
                {formErrors[name] ? <span style={{ color: 'red' }}>{formErrors[name]}</span> : ''}
            </>);
    };

    const getTextInput = (field, label = null) => {
        const hasError = formErrors[field] !== '';
        return (
            <>
                <LabeledInput className='align-with-select' label={label || field} name={field} value={form[field]} onChange={setFormFieldValue} onBlur={(e) => {
                    if (!validateForm(form)) {
                        return;
                    }
                    save(form);
                    saveExtra(form);
                }} />
                {hasError ? <span style={{ color: 'red' }}>{formErrors[field]}</span> : ''}
            </>
        );
    };

    const formFields = (
        <Row>
            <Col padded sm={6}>
                {getSelect({ label: 'Default Invoice View', name: 'default_invoice_view', options: invoice_view_options })}
            </Col>
            <Col padded sm={6}>
                {getSelect({ label: 'Default Currency', name: 'default_currency_id', options: currency_options })}
            </Col>
            <Col padded sm={6}>
                {getSelect({ label: 'Default PO Date Option', name: 'default_shipdate_option', options: shipdate_options })}
            </Col>
            <Col padded sm={6}>
                {getSelect({ label: 'Default Presentation', name: 'default_presentation_style', options: presentation_options })}
            </Col>
            <Col padded sm={6}>
                {getSelect({ label: 'Default Tax', name: 'default_tax_id', options: tax_options })}
            </Col>
            <Col padded sm={6}>
                {getTextInput('default_margin', 'Default Margin')}
            </Col>
            <Col padded sm={6}>
                {getSelect({ label: 'Default Client Rep For Imported Clients', name: 'default_client_rep_id', options: user_options })}
            </Col>
        </Row>
    );

    return formFields;
}
