import React, { useEffect, useState } from 'react';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import { Button, LabeledInput, Loading, Row } from '@commonsku/styles';
import WebhookSection, { WebhookProps, WebhookEditableProps } from './WebhookSection';
import config from '../../config';
import { parseRestBoolean } from '../../utils';
import { CompanyData } from '../../interfaces/CompanyData';

type WebhookIntegration = {
    mappings: {
        [key in keyof WebhookEditableProps]: keyof CompanyData;
    }
} & WebhookProps;

export default function WebhooksIntegration() {
    const [ { data: { companyData: data} }, loading, save, saveExtra ] = useCompanyGeneralData();
    const [ unsavedChanges, setUnsavedChanges ] = useState<boolean>(false);
    const [ webhooksPassword, setWebhooksPassword ] = useState<string>();
    const [ webhooks, setWebhooks ] = useState<WebhookIntegration[]>();
    const [ saveParams, setSaveParams ] = useState<Partial<CompanyData>>({});

    const onPasswordChange = (password: string) => {
        setWebhooksPassword(password);
        const isCurrentPasswordDifferent: boolean = !Boolean(data.webhooks_integration_password) ? password !== '' : password !== data.webhooks_integration_password;

        if (isCurrentPasswordDifferent) {
            setSaveParams({ ...saveParams,
                webhooks_integration_password: password
            })
        } else {
            let updated = { ...saveParams };
            delete updated.webhooks_integration_password;
            setSaveParams({ ...updated })
        }
    };
    
    useEffect(() => {
        if (Object.keys(saveParams).length > 0) {
            setUnsavedChanges(true);
        } else {
            setUnsavedChanges(false);
        }
    }, [ saveParams ]);

    useEffect(() => {
        if (data) {
            setWebhooks([
                {
                    name: 'PO Financials',
                    testModePrefix: config.po_financials_test_prefix,
                    enabled: parseRestBoolean(data.webhook_po_financial_enabled),
                    testModeEnabled: parseRestBoolean(data.webhook_po_financial_test_flag),
                    url: data.webhook_po_financial_url,
                    mappings: {
                        enabled: 'webhook_po_financial_enabled',
                        testModeEnabled: 'webhook_po_financial_test_flag',
                        url: 'webhook_po_financial_url',
                    }
                }
            ]);
            setWebhooksPassword(data.webhooks_integration_password);
        }
    }, [ data ]);

    const setWebhook = (i: number, webhookParams: Partial<WebhookEditableProps>) => {
        for (const [ key, value ] of Object.entries(webhookParams)) {
            const webhook = webhooks[i];
            const mapping: string = webhook.mappings[key];
            if (value !== webhooks[i][key]) {
                const updatedWebhooks = webhooks;
                updatedWebhooks[i][key] = value;
                setWebhooks(updatedWebhooks);

                let isCurrentMappingDifferent: boolean;
                switch(typeof value) {
                    case 'string':
                        isCurrentMappingDifferent = !Boolean(data[mapping]) ? value !== '' : value !== data[mapping];
                        break;
                    case 'boolean':
                        isCurrentMappingDifferent = Boolean(data[mapping]) !== value;
                }

                if (isCurrentMappingDifferent) {
                    setSaveParams({ ...saveParams,
                        [mapping]: value
                    });
                } else {
                    let updated = { ...saveParams };
                    delete updated[mapping];
                    setSaveParams({ ...updated })
                }
            }
        }
    };

    const saveWebhooks = () => {
        saveExtra(saveParams, true);
        setUnsavedChanges(false);
        setSaveParams({});
    };

    if (loading !== 'idle') {
        return <Loading />
    };

    return <div style={{ position: 'relative', height: '100%' }}>
        <div className="column tip" style={{marginBottom: 20}}>
            Webhooks allow for event-based data export from commonsku. commonsku will POST data for each enabled webhook to the provided external URL. Contact <a href='mailto:support@commonsku.com'>support@commonsku.com</a> to learn more.
        </div>
        
        <LabeledInput
          labelOnTop
          label='Password'
          name='password'
          placeholder='Your webhooks password'
          type='password'
          value={webhooksPassword}
          onChange={(e) => {
            onPasswordChange(e.target.value);
          }}
          style={{ width: 500 }}
        />

        {webhooks?.map((webhook, i) => {
            return <WebhookSection
                {... webhook}
                key={i}
                onChange={(updated) => {
                    setWebhook(i, updated);
                }}
            />;
        })}

        <Button
            style={{ position: 'absolute', right: 0, bottom: 30 }}
            disabled={!unsavedChanges}
            onClick={() => {
                saveWebhooks();
            }}
        >Save</Button>
    </div>;
}