import { AlertNotification, Row } from '@commonsku/styles';
import React from 'react';

interface WebhooksIntegrationPaywallProps {
    paywallCtaUrl: string
}

const WebhooksIntegrationPaywall = ({
    paywallCtaUrl
}: WebhooksIntegrationPaywallProps) => {
    return <><Row>
        <div>
            Webhooks allow for event-based data export from commonsku. commonsku will POST data for each enabled webhook to the provided external URL. Contact <a href='mailto:support@commonsku.com'>support@commonsku.com</a> to learn more.
        </div>

        <AlertNotification style={{width: '100%', marginTop: 20}} href={paywallCtaUrl} learnMore={true} target={'_blank'}>
            This feature is only available to enterprise customers
        </AlertNotification>
    </Row></>
};

export default WebhooksIntegrationPaywall;
