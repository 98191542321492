import React from 'react';
import { Row, Col, Button, LabeledRadioInButton, Loading } from '@commonsku/styles';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import ErrorBoundary from '../../components/ErrorBoundary';
import AdminQuickbooks from './AdminQuickbooks';
import CompanyCustomWebhook from './form/CompanyCustomWebhook';
import AdminXero from './AdminXero';
import { useUserFlags } from '../../hooks';

export default function AccountingIntegration() {
    const [{ data }, loading, save, saveExtra, identity] = useCompanyGeneralData();
    const { hasUserFlag } = useUserFlags(identity);
    const accountingSoftware = data?.companyData?.accounting_software;

    const handleChangeExtraData = (field, value) => {
        saveExtra({
            [field]: value
        });
    };

    if (loading !== 'idle') return <Loading />;

    const canUseCustomWebhook = hasUserFlag('WEBHOOKS_ENABLED') || accountingSoftware === 'WEBHOOK';

    return (
        <ErrorBoundary>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <LabeledRadioInButton flexGrow label="QuickBooks" checked={accountingSoftware === 'QBO'} onChange={(e) => { handleChangeExtraData('accounting_software', 'QBO'); }} />
                <LabeledRadioInButton flexGrow label="Xero" checked={accountingSoftware === 'XERO'} onChange={(e) => { handleChangeExtraData('accounting_software', 'XERO'); }} />
                {canUseCustomWebhook ? <LabeledRadioInButton flexGrow label="Custom Webhook" checked={accountingSoftware === 'WEBHOOK'} onChange={(e) => { handleChangeExtraData('accounting_software', 'WEBHOOK'); }} /> : null}
                <LabeledRadioInButton flexGrow label="XML Export" checked={accountingSoftware === 'XML'} onChange={(e) => { handleChangeExtraData('accounting_software', 'XML'); }} />
                <LabeledRadioInButton flexGrow label="CSV Export" checked={!accountingSoftware} onChange={(e) => { handleChangeExtraData('accounting_software', ''); }} />
            </div>

            <div style={{ padding: '1em 0' }}>
                {accountingSoftware === 'XERO'
                    ? <AdminXero />
                    : accountingSoftware === 'QBO'
                        ? <AdminQuickbooks />
                        : accountingSoftware === 'WEBHOOK'
                            ? <CompanyCustomWebhook save={saveExtra} data={data?.companyData} />
                            : accountingSoftware === 'XML'
                                ? null
                                : null}
            </div>
        </ErrorBoundary>
    );
}
