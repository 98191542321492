import React from 'react';
import { LabeledInput, LabeledCheckbox, ToggleSwitch, Toggle, ToggleLink } from '@commonsku/styles';

export type WebhookEditableProps = {
    enabled: boolean,
    url: string
    testModeEnabled: boolean,
}

export type WebhookProps = {
    name: string,
    testModePrefix: string,
} & WebhookEditableProps;

type WebhookSectionProps = {
    onChange: (params: Partial<WebhookEditableProps>) => void;
} & WebhookProps;

export default function WebhookSection({
    name,
    enabled,
    url,
    testModeEnabled,
    testModePrefix,
    onChange
}: WebhookSectionProps) {
    return <>
        <div style={{width: '100%', background: '#E1F7FA', padding: 20}}>
            <ToggleSwitch
                style={{float: 'right'}}
                onClick={() => onChange({ enabled: !enabled })}
                initialSelected={enabled}
            />

            <h3>{name}</h3>

            <LabeledInput
                label='URL'
                onChange={(e) => onChange({ url: e.target.value })}
                value={url}
            />

            <LabeledCheckbox
                style={{marginBottom: 0}}
                label='Test Mode'
                checked={testModeEnabled}
                onChange={() => onChange({ testModeEnabled: !testModeEnabled })}
            />
            <p style={{color: '#899CA9', marginLeft: 30}}>Only projects with {testModePrefix} at the beginning of the project name will be exported.</p>
        </div>
    </>;
}