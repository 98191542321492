import { SkubotSpinner, ToggleSwitchStyled } from '@commonsku/styles';
import React from 'react';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';

export default function AdminHubspotImportToggle() {
    const [data, loading, , saveExtra] = useCompanyGeneralData();
    const enabled = data?.data?.companyData?.hubspot_import_enabled === 1 || false;
    return (
        <div>
            {loading === 'pending' ? <SkubotSpinner size="button" skubot={false} /> :
                <ToggleSwitchStyled
                    size="small"
                    selected={enabled}
                    onClick={() => {
                        saveExtra({
                            hubspot_import_enabled: !enabled,
                        }, true);
                    }}
                    style={{ maxHeight: '25px', alignSelf: 'center' }}
                />}
        </div>

    );
}
