import { useEffect, useMemo, useState } from "react";
import { oauth } from "../../../utils";
import { useIdentity, useUserFlags } from "../../../hooks";

const payloadMap = {
  get_tax_codes: "TaxCode",
  get_items: "Item",
  get_accounts: "Account",
  get_custom_fields: "CustomField",
  get_currencies: "CompanyCurrency",
  get_classes: "Class",
};

const preparePayload = (useNewApi, action) => {
  if (useNewApi) {
    return {
      action: "get_entity",
      entity_name: payloadMap[action],
    };
  } else {
    return { action };
  }
};

export default function useQuickbookGetMappingOptions() {
  const identity = useIdentity();
  const { hasUserFlag } = useUserFlags(identity);
  const newApi = useMemo(() => hasUserFlag("USE-NEW-QBO"), [hasUserFlag]);
  const qboPath = useMemo(() => (newApi ? "quickbooks" : "qbo"), [newApi]);

  const [qboCurrencies, setQboCurrencies] = useState([]);
  const [qboItems, setQboItems] = useState([]);
  const [qboTaxCodes, setQboTaxCodes] = useState([]);
  const [qboAccounts, setQboAccounts] = useState([]);
  const [qboCustomFields, setQboCustomFields] = useState([]);
  const [qboInvoiceFields, setQboInvoiceFields] = useState([]);
  const [qboClasses, setQboClasses] = useState([]);

  useEffect(() => {
    const call = async () => {
      const res = await oauth(
        "POST",
        qboPath,
        preparePayload(newApi, "get_tax_codes"),
      );
      const items = [];
      for (const key in res.json.taxes) {
        items.push({ name: res.json.taxes[key], ref: key });
      }
      setQboTaxCodes(items);
    };
    call();
  }, [newApi, qboPath]);

  useEffect(() => {
    const call = async () => {
      const res = await oauth(
        "POST",
        qboPath,
        preparePayload(newApi, "get_items"),
      );
      const items = [];
      for (const key in res.json.items) {
        items.push({ name: res.json.items[key], ref: key });
      }
      setQboItems(items);
    };
    call();
  }, [newApi, qboPath]);

  useEffect(() => {
    const call = async () => {
      const res = await oauth(
        "POST",
        qboPath,
        preparePayload(newApi, "get_accounts"),
      );
      const items = [];
      for (const key in res.json.accounts) {
        items.push({ ...res.json.accounts[key], ref: key });
      }
      setQboAccounts(items);
    };
    call();
  }, [newApi, qboPath]);

  useEffect(() => {
    const call = async () => {
      const res = await oauth(
        "POST",
        qboPath,
        preparePayload(newApi, "get_custom_fields"),
      );
      const items = [];
      for (const key in res.json.custom_fields) {
        items.push(res.json.custom_fields[key]);
      }
      setQboCustomFields(items);
    };
    call();
  }, [newApi, qboPath]);

  useEffect(() => {
    const call = async () => {
      const res = await oauth(
        "POST",
        qboPath,
        preparePayload(newApi, "get_currencies"),
      );
      const items = [];
      for (const key in res.json.currencies) {
        items.push(res.json.currencies[key]);
      }
      setQboCurrencies(items);
    };
    call();
  }, [newApi, qboPath]);

  useEffect(() => {
    const call = async () => {
      const res = await oauth("POST", qboPath, {
        action: "get_invoice_fields",
      });
      const items = [];
      for (const key in res.json.invoice_fields) {
        items.push(res.json.invoice_fields[key]);
      }
      setQboInvoiceFields(items);
    };
    call();
  }, [qboPath]);

  useEffect(() => {
    const call = async () => {
      const res = await oauth(
        "POST",
        qboPath,
        preparePayload(newApi, "get_classes"),
      );
      const items = [];
      for (const key in res.json.classes) {
        items.push(res.json.classes[key]);
      }
      setQboClasses(items);
    };
    call();
  }, [newApi, qboPath]);

  return [
    qboCurrencies,
    qboItems,
    qboTaxCodes,
    qboAccounts,
    qboCustomFields,
    qboInvoiceFields,
    qboClasses,
  ];
}
